export { matchers } from './client-matchers.js';

export const components = [
	() => import("..\\..\\src\\routes\\__layout.svelte"),
	() => import("..\\..\\src\\routes\\__error.svelte"),
	() => import("..\\..\\src\\routes\\manager\\[project]\\__layout.svelte"),
	() => import("..\\..\\src\\routes\\[section]\\[output]\\[tool].svelte"),
	() => import("..\\..\\src\\routes\\[section]\\[output].svelte"),
	() => import("..\\..\\src\\routes\\administration.svelte"),
	() => import("..\\..\\src\\routes\\index.svelte"),
	() => import("..\\..\\src\\routes\\invitation.svelte"),
	() => import("..\\..\\src\\routes\\lgpd\\index.svelte"),
	() => import("..\\..\\src\\routes\\lgpd\\privacy.svelte"),
	() => import("..\\..\\src\\routes\\lgpd\\term.svelte"),
	() => import("..\\..\\src\\routes\\manager\\[project]\\manage\\access.svelte"),
	() => import("..\\..\\src\\routes\\manager\\[project]\\manage\\edit.svelte"),
	() => import("..\\..\\src\\routes\\manager\\[project]\\manage\\progress.svelte"),
	() => import("..\\..\\src\\routes\\manager\\[project]\\monitoring.svelte"),
	() => import("..\\..\\src\\routes\\manager\\[project]\\resume.svelte"),
	() => import("..\\..\\src\\routes\\manager\\[project]\\team.svelte"),
	() => import("..\\..\\src\\routes\\manager\\access.svelte"),
	() => import("..\\..\\src\\routes\\manager\\index.svelte"),
	() => import("..\\..\\src\\routes\\manager\\presence.svelte"),
	() => import("..\\..\\src\\routes\\methodology.svelte"),
	() => import("..\\..\\src\\routes\\profile.svelte"),
	() => import("..\\..\\src\\routes\\project.svelte"),
	() => import("..\\..\\src\\routes\\share-project.svelte"),
	() => import("..\\..\\src\\routes\\teams.svelte"),
	() => import("..\\..\\src\\routes\\videos.svelte")
];

export const dictionary = {
	"": [[0, 6], [1]],
	"administration": [[0, 5], [1]],
	"invitation": [[0, 7], [1]],
	"lgpd": [[0, 8], [1]],
	"manager": [[0, 18], [1]],
	"methodology": [[0, 20], [1]],
	"profile": [[0, 21], [1]],
	"project": [[0, 22], [1]],
	"share-project": [[0, 23], [1]],
	"teams": [[0, 24], [1]],
	"videos": [[0, 25], [1]],
	"lgpd/privacy": [[0, 9], [1]],
	"lgpd/term": [[0, 10], [1]],
	"manager/access": [[0, 17], [1]],
	"manager/presence": [[0, 19], [1]],
	"manager/[project]/monitoring": [[0, 2, 14], [1]],
	"manager/[project]/resume": [[0, 2, 15], [1]],
	"manager/[project]/team": [[0, 2, 16], [1]],
	"manager/[project]/manage/access": [[0, 2, 11], [1]],
	"manager/[project]/manage/edit": [[0, 2, 12], [1]],
	"manager/[project]/manage/progress": [[0, 2, 13], [1]],
	"[section]/[output]": [[0, 4], [1]],
	"[section]/[output]/[tool]": [[0, 3], [1]]
};